@media (orientation: portrait){



}

@media (orientation: landscape) {



}
.wrapper{
    width: 94%;
    height: auto;
    background: white;
    border-radius: 10px;
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    flex-direction: column;

}
.wrapper>h3{
    background: #01224f;
    color: gold;
    width: 90%;
    border-radius: 8px;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;

}
.wrapper>h4{
    background: gold;
    color: #01224f;
    width: 90%;
    border-radius: 8px;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;

}
.price{
    color: orangered;
}

.description{
    padding-left: 2rem;
    padding-right: 1rem;
    display: block;
    text-align: left;
    white-space: pre-wrap;
}