@media (orientation: portrait) {
    .wrapperItems {

        width: 100%;
        height: auto;
        display: flex;
        flex-direction: row;
        border-top: solid 1px black;
        border-left: solid 1px black;
        border-right: solid 1px black;
        font-size: 0.7rem;
    }
    .wrapperItemsFinaly{
        width: 100%;
        height: 2rem;
        border-top: solid 1px black;
        border-right: solid 1px black;;

    }
}

@media (orientation: landscape) {
    .wrapperItems {

        width: 40%;
        height: auto;
        display: flex;
        flex-direction: row;
        border: solid 1px black;
    }
    .wrapperItemsFinaly{
        width: 30%;
        border-top: solid 1px black;
        padding: 5px;
    }
}

.wrapper {
    padding-top: 2rem;
    width: 98%;
    height: 100%;
    background: white;
    border-radius: 10px;
    margin-bottom: 1rem;


}


.wrapperItemsTitle {

    width: 30%;
    height: auto;
    padding: 5px;
    border-left: solid 1px black;
    display: flex;
    align-items: start;
    justify-content: left;
    text-align: left;

}

.wrapperItemsContent {
    width: 70%;
    height: auto;
    padding: 5px;
    border-left: solid 1px black;
    display: flex;
    align-items: start;
    justify-content: left;
    text-align: left;

}
.wrapperItemsContent>span{
    width: 100%;
    padding-left: 1rem;

}
.wrapperItemsContent>a{
    width: 100%;
    padding-left: 1rem;

}




