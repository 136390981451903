@media (orientation: portrait) {
    .wrapper {
        position: fixed;
        bottom: 0;
        width: 100%;
        height: 2rem;
        background: #01224f;
        color: #fff;
        display: flex;
        flex-direction: row;
        justify-content: left;
        align-items: center;
        transition: all 0.5s;
        z-index: 500;
    }

    .wrapper_show {
        left: 0;
    }

    .wrapper_hide {

    }

    .wrapper_showRoutes_BT {
        position: fixed;
        right: 0;
        width: 2rem;
        height: 2rem;
        top: 0;
        color: red;

    }

    .wrapper_routeItem {
        width: 100%;
        height: 100%;
        border: solid 1px grey;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background: #01224f;
        color: white;
    }
    .wrapper_routeItem_name{
        font-size: 0.7rem;

    }
    .wrapper_routeItem_img{
        width: 2rem;
        height: 2rem;
        object-fit: contain;

    }
    .selectedItem {
        background: white;
        color: sandybrown;

    }

}

@media (orientation: landscape) {

    .wrapper {
        width: 100%;
        height: 2rem;
        background: #aaa;
        color: #222;
        display: flex;
        flex-direction: row;
        justify-content:flex-start;
        align-items: center;
        z-index: 500;
    }

    .wrapper_routeItem {

        border: solid 1px grey;
        background: white;
        display: flex;
        flex-direction: row;
        margin-left: 1rem;
        padding: 0.2rem 1rem 0.2rem 1rem;
        white-space: nowrap;
        border-radius: 0.3rem;
        height: 70%;
    }
    .wrapper_routeItem_name{
        height: 1rem;
        width: auto;

    }
    .wrapper_routeItem_img{
        display: none;

    }

    .defItem {
        color: black;
        background: lightgrey;
        box-shadow: 0 0 0 1px teal;
    }

    .hidden_item {
        display: none;
    }



    .selectedItem {
        color: #20b2aa;
        background-color: white;
        box-shadow: 0 0 0 3px teal;

    }
}
