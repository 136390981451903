@media (orientation: portrait) {
    .wrapper_scanners{
        width: 100%;
        height: 4rem;
        display: flex;
        flex-direction: row ;
        background: fuchsia;
    }
    .wrapper_scanner {
        width: 4rem;
        height: 4rem;

    }
    .wrapper_logo_img{
        width: 100%;
        height: auto;
    }
}

@media (orientation: landscape) {
    .wrapper_scanners{
        width: 100%;
        height: 2rem;
        display: flex;
        flex-direction: row ;
        background: sandybrown;
    }
    .wrapper_scanner {
        width: 9rem;
        height: 2rem;

    }
}


.wrapper {
    width: 94%;
    height: 100%;
    padding-left: 1rem;
    background: white;
    padding-top: 1rem;
    border-radius: 10px;
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    flex-direction: column;
}
.wrapper>p{

    color: black;
    display: block;
    text-align: left;
    white-space: pre-wrap;

}
