@media (orientation: portrait) {
    .wrapperSvg{
        position: absolute;
        display: flex;
        align-items: center;
        top: 0;

    }
}

@media (orientation: landscape) {
    .wrapperSvg{
        position: absolute;
        display: flex;
        align-items: center;
        top: 0;
        width: 30%;

    }
}

.wrapper {
    width: 94%;
    height: 150%;
    padding-left: 1rem;
    background: white;
    padding-top: 2rem;
    border-radius: 10px;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.wrapper>p{
    color: black;
    display: block;
    text-align: left;
    white-space: pre-wrap;

}
.fil4 {fill:#F7A707}
.fil10 {fill:#1AA6DE}
.fil3 {fill:#01549A}
.fil9 {fill:#4DB45E}
.fil0 {fill:#CC1626}
.fil2 {fill:#FFCC00}
.fil1 {fill:white}
.fil7 {fill:#F59F0D;fill-rule:nonzero}
.fil5 {fill:#EA5E20;fill-rule:nonzero}
.fil6 {fill:#DE1E26;fill-rule:nonzero}
.fil8 {fill:white;fill-rule:nonzero}
