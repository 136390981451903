@media (orientation: portrait){
    body {
        font-family: "Arial";
        background:#01224f;
        width: 100vw;
        height: 100vh;
        user-select: none;
        box-sizing: content-box;
        padding: 0;
        margin: 0;
        font-size: 4vw;
    }

    #root {
        background:#01224f;
        position: fixed;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
    }

    .contentHead {

        position: absolute;

        top:0;
        width: 100%;
        height: 10%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        animation-duration: 500ms;
        animation-name: contentHeadAnim;
    }

    .routerPageContent {
        top:10%;
        position: absolute;
        background: lightgrey;
        width: 100%;
        height: 85%;
        animation-duration: 500ms;
        display: flex;
        flex-direction: column;
        align-items: center;
        animation-name: slideinContent;
        overflow: scroll;
    }


}

@media (orientation: landscape) {
    body {
        font-family: "Arial";
        background:grey;
        width: 100%;
        height: 100%;
        user-select: none;
        box-sizing: content-box;
        padding: 0;
        margin: 0;
        font-size: 2vh;
    }
    #root {
        background:#01224f;
        position: fixed;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
    }
    .contentHead {
         background: orangered;
        position: absolute;
        top:5%;
        width: 100%;
        height: 5%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        animation-duration: 500ms;
        animation-name: contentHeadAnim;
    }


    .routerPageContent {
        top:25%;
        position: absolute;
        background: lightgrey;
        width: 100%;
        height: 85%;
        animation-duration: 500ms;
        display: flex;
        flex-direction: column;
        align-items: center;
        animation-name: slideinContent;
        overflow: scroll;
    }
}

.contentHead>img{
    height: 100%;

}
.contentHead>h2{
    color: white;
    margin-left: 1em;
}
@keyframes contentHeadAnim {
    from {
        margin-left: -100%;

    }

    to {
        margin-left: 0;

    }
}

@keyframes contentHeadCloseAnim {
    from {
        margin-left: 0%;

    }

    to {
        margin-left: 100%;

    }
}


.routerPageContent>span{
    padding-left: 2rem;
    padding-right: 1rem;
    display: block;
    text-align: left;
    white-space: pre-wrap;


}

@keyframes slideinContent {
    from {
        margin-left: 100%;

    }

    to {
        margin-left: 0;

    }
}

.fil4 {fill:#F7A707}
.fil10 {fill:#1AA6DE}
.fil3 {fill:#01549A}
.fil9 {fill:#4DB45E}
.fil0 {fill:#CC1626}
.fil2 {fill:#FFCC00}
.fil1 {fill:white}
.fil7 {fill:#F59F0D;fill-rule:nonzero}
.fil5 {fill:#EA5E20;fill-rule:nonzero}
.fil6 {fill:#DE1E26;fill-rule:nonzero}
.fil8 {fill:white;fill-rule:nonzero}
